import api from "@/base/utils/request";

// 获取基本设置
export const taskList = data => {
  return api({
    url: "/admin/incomes/integral_set/index",
    method: "post",
    data
  });
};

// 保存任务配置
export const saveTask = data => {
  return api({
    url: "/admin/incomes/integral_set/save",
    method: "post",
    data
  });
};