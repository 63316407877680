<template>
  <div class="list-page">
    <div class="list-content" v-loading="tableLoading">
      <el-table
        :key="renderKey"
        :data="tbody.length && handleMinWidth(thead.find(t => t.prop === 'cycle')) ? tbody : []"
        style="width: 100%"
        class="thead-light"
        stripe
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(th, thI) in thead"
          :key="th.prop"
          :label="th.label"
          :prop="th.prop"
          :width="th.width || ''"
          :min-width="handleMinWidth(th)"
          :fixed="th.fixed"
        >
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <!-- <template v-if="th.prop==='operate'">
              <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button>
              <el-button size="small" type="text" @click="onDelete(row.id)">删除</el-button>
            </template> -->
            <!-- 启用状态 -->
            <el-switch
              v-if="th.prop === 'status'"
              :active-value="1"
              :inactive-value="0"
              v-model="row[th.prop]"
            >
            </el-switch>
            <div class="list-editor" v-else-if="th.prop === 'cycle'">
              <el-select v-model="row[th.prop].cycle_type">
                <el-option
                  v-for="(item, index) in cycle_type_options"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <div v-if="row[th.prop].cycle_type === 2">
                周期
                <el-input
                  type="number"
                  v-model.number="row[th.prop].cycle_num"
                ></el-input>
                次数
                <el-input
                  type="number"
                  v-model.number="row[th.prop].number"
                ></el-input>
              </div>
            </div>
            <el-input
              placeholder="选填"
              v-else-if="['new_name'].includes(th.prop)" v-model="row[th.prop]"
            ></el-input>
            <el-input
              type="number"
              v-else-if="['integral', 'experience'].includes(th.prop)"
              v-model.number="row[th.prop]"
            ></el-input>
            <!-- 其他 -->
            <span v-else>{{ row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </el-table>
      <FixedActionBar>
        <el-button
          type="primary"
          size="medium"
          @click="formSubmit"
          :loading="saveLoading"
          >保存
        </el-button>
      </FixedActionBar>
    </div>
  </div>
</template>
<script>
import { taskList, saveTask } from '../api/task-config'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { timestamp } from '../../../base/utils/tool'

export default {
  components: { FixedActionBar },
  data() {
    return {
      tableLoading: false, //表格加载中
      saveLoading: false,
      thead: [
        { label: '开启', prop: 'status', minWidth: 100 },
        { label: '类型', prop: 'type_text', minWidth: 110 },
        // { label: "名称", prop: "name", minWidth: 410 },
        { label: "功能", prop: "name", minWidth: 300 },
        { label: "名称", prop: "new_name", minWidth: 300 },
        // { label: "周期", prop: "cycle", minWidth: 450 },
        { label: '周期', prop: 'cycle', minWidth: 100 },
        { label: '站点积分', prop: 'integral', minWidth: 120 },
        { label: '成长值', prop: 'experience', minWidth: 120 },
      ],
      tbody: [],
      cycle_type_options: [
        { id: 0, name: '一次性' },
        { id: 1, name: '不限' },
        { id: 2, name: '天' },
      ],
      renderKey: '',
    }
  },
  methods: {
    handleMinWidth(th) {
      const minWidth = (
        (th.prop === 'cycle'
          ? this.tbody.filter((t) => t.cycle.cycle_type === 2).length
            ? 450
            : ''
          : '') ||
        th.minWidth ||
        ''
      )
      if (th.prop === 'cycle' && this.tbody.length && minWidth && minWidth !== this._minWidth) {
        this.renderKey = timestamp() // 强制刷新
        this._minWidth = minWidth
      }
      return minWidth
    },
    //获取数据列表
    getTaskList() {
      this.tableLoading = true
      this.saveLoading = true
      taskList()
        .then((res) => {
          const { data } = res
          this.tbody = data
          this.tableLoading = false
          this.saveLoading = false
        })
        .catch((err) => {
          this.tableLoading = false
          this.saveLoading = false
        })
    },
    // 保存
    formSubmit() {
      this.saveLoading = true
      this.tableLoading = true
      saveTask({ config: JSON.stringify(this.tbody) })
        .then((res) => {
          this.$message.success(res.msg)
          this.getTaskList()
        })
        .catch(() => {
          this.saveLoading = false
          this.tableLoading = false
        })
    },
  },
  created() {
    //获取数据列表
    this.getTaskList()
  },
}
</script>

<style lang="scss" scoped>
.list-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 50%;
}
.list-editor {
  display: flex;
  align-items: center;
  .el-select {
    width: 100px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .el-input {
    width: 100px;
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 10px;
  }
}
</style>
